<template>
    <div class="u-height-100">
        <div
            ref="mapLoader"
            class="gst-map-loader"
            :style="{ height, width }"></div>
        <template v-if="Boolean( mapsApi ) && Boolean( map )">
            <slot
                :mapsApi="mapsApi"
                :map="map"></slot>
        </template>
    </div>
</template>

<script>
    import 'mapbox-gl/dist/mapbox-gl.css';
    import mapboxgl from 'mapbox-gl';
    import debounce from 'lodash/debounce';
    import MAP_CONSTANTS from '@core/utils/constants/apiMapLocation.js';

    export default {
        name: 'MapLoader',
        props: {
            // check mapbox API documentation for map configuration
            mapConfig: {
                type: Object,
                default: () => {}
            },
            height: {
                type: String,
                default: '100%'
            },
            width: {
                type: String,
                default: '100%'
            }
        },
        data( ) {
            return {
                mapsApi: null,
                map: null,
                zoomControl: null
            };
        },
        computed: {
            isDesktopVersion( ) {
                return this.$vuetify.breakpoint.mdAndUp;
            }
        },
        watch: {
            isDesktopVersion: function( newValue ) {
                if ( newValue ) {
                    this.map.addControl( this.zoomControl );
                } else if ( this.map.hasControl( this.zoomControl ) ) {
                    this.map.removeControl( this.zoomControl );
                }
            },
            'mapConfig': {
                handler: function ( value ) {
                    if ( value.bounds ) {
                        this.map.fitBounds( value.bounds, value.fitBoundsOptions );
                    } else if ( value.center ) {
                        this.map.jumpTo( {
                            center: [ ...value.center ],
                            zoom: value.zoom || 10
                        } );
                    }
                },
                deep: true
            }
        },
        methods: {
            mapResize: debounce( function ( ) {
                if ( this.map ) {
                    this.map.resize( );
                }
            }, 50 ),
            initZoomControl( ) {
                this.zoomControl = new mapboxgl.NavigationControl( {
                    showCompass: false
                } );

                if ( this.isDesktopVersion ) {
                    this.map.addControl( this.zoomControl );
                }
            }
        },
        mounted( ) {
            this.mapsApi = mapboxgl;
            mapboxgl.accessToken = MAP_CONSTANTS.CONFIG.MAPBOX.apiKey;
            
            this.map = new mapboxgl.Map( {
                container: this.$refs.mapLoader, // container ID
                style: 'mapbox://styles/mapbox/streets-v12', // style URL
                trackResize: false, // map will be resized through event listeners because the container is resized via CSS
                ...this.mapConfig
            } );

            this.initZoomControl( );

            window.addEventListener( 'resize', this.mapResize );
        },
        beforeDestroy( ) {
            window.removeEventListener( 'resize', this.mapResize );
            this.map.remove( );
        }
    };
</script>