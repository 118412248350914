<template>
    <v-toolbar
        flat
        pa-0
        :min-height="$vuetify.breakpoint.mdAndUp ? 85 : 66"
        :height="$vuetify.breakpoint.mdAndUp ? 85 : 66"
        class="gst-hotel-details-header">
        <div
            v-if="hasBackButton"
            class="align-self-start ml-n2">
            <BaseButton
                text
                color="primary"
                class="gst-btn--link "
                @click="$emit( 'back' )">
                <BaseIcon symbol-id="icons--chevron_left" class="gst-event-ticket-filter__btn-filter-icon mr-2" />
                {{ backButtonText }}
            </BaseButton>
        </div>
        <div class="gst-hotel-details-header__first-row mb-md-2">
            <VClamp
                :max-lines="1"
                autoresize
                class="gst-hotel-details-header__title mr-2 mr-sm-0">
                {{ item.name }}
            </VClamp>
            <StarRating :key="stars" :value="stars" class="gst-add-hotel-reservation-hotel-list-card__rating d-flex align-center" />
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
            <v-btn
                v-if="!$vuetify.breakpoint.mdAndUp"
                fab
                text
                x-small
                class="gst-hotel-details-header__icon-close text-capitalize gst-btn--cancel px-0"
                @click="$emit('close')">
                <IconClose />
            </v-btn>
        </div>

        <slot name="addressBar"></slot>
    </v-toolbar>
</template>

<script>
    import VClamp from 'vue-clamp';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import IconClose from '@core/shared/assets/icons/close.svg';
    import StarRating from '@core/shared/components/misc/StarRating.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'HotelDetailsHeader',
        components: {
            IconClose,
            StarRating,
            VClamp,
            BaseButton,
            BaseIcon
        },
        mixins: [
            CloseModalOnRouteChangeMixin
        ],
        props: {
            item: {
                type: Object,
                required: true
            },
            hasBackButton: {
                type: Boolean,
                default: false
            },
            backButtonText: {
                type: String,
                default: function ( ) {
                    return this.$t( '_common:buttons.back' );
                }
            }
        },
        computed: {
            stars( ) {
                const { awards } = this.item;

                if ( awards.length && awards[ 0 ].rating ) {
                    return awards[ 0 ].rating;
                }

                return 0;
            },
            rating( ) {
                return Array.isArray( this.item.awards ) && this.item.awards.length ? this.item?.awards[0].rating : 0;
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-hotel-details-header {
    position: sticky !important;
    top: 0;
    width: 100%;
    background-color: theme-color( 'white' ) !important;
    color: theme-color( 'quaternary' );
    z-index: $z-index-event-page-over-layer-top;
    flex-direction: column;

    .v-toolbar__content {
        flex-direction: column;
        justify-content: center;
    }

    .gst-hotel-details-header__icon-close {
        fill: theme-color( 'tertiary' );
    }

    .gst-hotel-details-header__first-row {
        display: flex;
        width: 100%;
        max-height: 85px;

        .gst-add-hotel-reservation-hotel-list-card__rating {
            position: relative;
            top: 2px;
            margin-left: theme-spacing( 2 );
        }

        .gst-hotel-details-header__title {
            font-size: font-size( 'xxl' );
            font-weight: font-weight( 'large' );
            letter-spacing: -0.1px;
            text-transform: capitalize;
            min-height: 20px;
        }

        .gst-hotel-details-header__rating svg {
            display: flex;
            align-self: center;
        }
    }

    .gst-hotel-details-header__divider {
        border-color: theme-color( 'quinary' );
    }
}

@include mobile-only {
    .gst-hotel-details-header {
        height: unset !important;
        flex-direction: column;
        min-height: unset !important;

        .v-toolbar__content {
            height: auto !important;
            padding: 0;
        }

        .gst-hotel-details-header__first-row {
            width: 100%;
            padding: theme-spacing( 3 ) theme-spacing( 0 );
            background-color: theme-color( 'primary' ) !important;
            max-height: 66px;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .gst-hotel-details-header__title {
                line-height: line-height( 'xxxl' );
                padding: theme-spacing( 0, 4 );
                margin-bottom: theme-spacing( 1 );
                color: theme-color( 'white' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'regular' );
                min-height: 20px;
            }

            .gst-hotel-details-header__rating {
                justify-content: center;
                align-items: center;
            }

            .gst-hotel-details-header__icon-close {
                position: absolute;
                top: calc( 50% - 14px );
                right: 0;

                .gst-svg-icon {
                    fill: theme-color( 'white' ) !important;
                }
            }
        }
    }
}
</style>
