import MAP_CONSTANTS from '@core/utils/constants/apiMapLocation.js';
import LogService from '@core/services/LogService';
import fetch from '@core/utils/fetch';

export default {
    /**
     * 
     * @param {*} profile - mapbox routing profile 
     * @param {*} coordinates - A semicolon-separated list of between two and 25 {longitude},{latitude} coordinate pairs to visit in order.
     * @returns 
     * 
     * https://docs.mapbox.com/api/navigation/directions/
     */
    getDirection: ( profile = MAP_CONSTANTS.TRAVEL_MODE.DRIVING, coordinates = [ ] ) => {
        const paramCoordinates = coordinates.map( item => item.join( ',', ) ).join( ';' );
        const accessToken = MAP_CONSTANTS.CONFIG.MAPBOX.apiKey;

        return fetch
            .get(
                `https://api.mapbox.com/directions/v5/mapbox/${profile}/${paramCoordinates}?steps=true&geometries=geojson&access_token=${accessToken}`
            )
            .then( ( { data } ) => { return { success: true, data }; } )
            .catch( e => {
                LogService.debug( 'Error getting MapBox Direction:', e.message );
                return { success: false, message: e.message, error: e }; } 
            );
    } 
};
