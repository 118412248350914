import locationsConstants from '@tenant/app/utils/constants/locations';

export function locationParser( location, includeName = false ) {
    const ret = [];

    if ( includeName ) {
        ret.push( location.name );
    }

    if ( location.type === locationsConstants.TYPES.VENUE ) {
        ret.push( location.city );
    }

    if ( location.state ) {
        ret.push( location.state );
    } else if ( location.stateCode ) {
        ret.push( location.stateCode );
    }

    ret.push( location.countryCode );

    return ret.join( ', ' );
}

export function getTranslateKeyForType( location ) {
    return location.type === locationsConstants.TYPES.CITY ? '_common:terms.cityCenter' : '_common:terms.venue';
}
