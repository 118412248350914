<template>
    <div>
        <image-masonry-variant-1 v-if="$vuetify.breakpoint.mdAndUp"
            :images="images" />
        <HotelRoomsCarousel v-else-if="$vuetify.breakpoint.mdAndDown" :images="images" />
    </div>
</template>

<script>
    import HotelRoomsCarousel from '@tenants/ticketmaster/app/views/eventV2/TheEventPackageOffer/components/HotelRoomsCarousel.vue';
    import ImageMasonryVariant1 from '@tenants/ticketmaster/app/components/images/ImageMasonryVariant1';

    export default {
        name: 'HotelDetailsGallery',
        components: {
            ImageMasonryVariant1,
            HotelRoomsCarousel
        },
        props: {
            images: {
                required: true,
                type: Array
            }
        }
    };
</script>
