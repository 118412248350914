<template>
    <div ref="estimation" class="d-flex align-center">
        <WalkingIcon v-if="isTravelModeWalking" class="walking-time-icon" />
        {{ travelTime }}
    </div>
</template>
<script>
    import { point as turfPoint } from '@turf/turf';
    import turfDistance from '@turf/distance';
    import WalkingIcon from '@tenant/app/assets/icons/walking.svg';
    import MAP_LOCATION from '@tenant/app/utils/constants/mapLocation.js';
    import MapDirectionService from '@tenants/ticketmaster/app/components/map/MapDirectionService.js';

    const DIRECTIONS_LAYER = 'route';

    export default {
        name: 'MapDirections',
        components: {
            WalkingIcon
        },
        props: {
            mapsApi: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            /** @type {Location} */
            from: {
                type: Object,
                required: true,
            },
            /** @type {Location} */
            to: {
                type: Object,
                required: true,
            },
        },
        data( ) {
            return {
                estimatedTimeInSeconds: 0,
                infoPopup: null
            };
        },
        computed: {
            fitBoundsPadding( ) {
                return this.$vuetify.breakpoint.mdAndUp ? 100 : 50;
            },
            updateTrigger( ) {
                return JSON.stringify( this.from ) + JSON.stringify( this.to );
            },
            travelMode( ) {
                const { from, to } = this;
                const pointFrom = turfPoint( [ from.lng, from.lat ] );
                const pointTo = turfPoint( [ to.lng, to.lat ] );
                const distance = turfDistance( pointFrom, pointTo, { units: 'kilometers' } );

                return distance > 2 ?  MAP_LOCATION.TRAVEL_MODE.DRIVING : MAP_LOCATION.TRAVEL_MODE.WALKING;
            },
            isTravelModeWalking() {
                return this.travelMode === MAP_LOCATION.TRAVEL_MODE.WALKING;
            },
            travelTime( ) {
                const hours = Math.floor( this.estimatedTimeInSeconds / 3600 );
                const minutes = Math.floor( ( this.estimatedTimeInSeconds - hours * 3600 ) / 60 );

                const hoursText =  hours > 0 ? this.$t( '_common:terms.hourWithCount', { count: hours } ) : '';
                const minutesText =  minutes > 0 ? this.$t( '_common:terms.minuteWithCount_abv', { count: minutes } ) : '';
                const translationKey = this.isTravelModeWalking ?  'walkingTime' : 'drivingTime';

                return this.$t( translationKey, { amount: `${hoursText} ${minutesText}`, to: this.to.name } );
            }
        },
        watch: {
            updateTrigger: function ( newValue, oldValue ) {
                if ( newValue !== oldValue ) {
                    this.init();
                }
            }
        },
        methods: {
            init( ) {
                this.cleanPreviousRoute();
                this.map.once( 'idle', this.addRoute );
            },
            cleanPreviousRoute( ) {
                if ( this.map.getLayer( DIRECTIONS_LAYER ) ) {
                    this.map.removeLayer( DIRECTIONS_LAYER );
                    this.map.removeSource( DIRECTIONS_LAYER );
                }
                if ( this.infoPopup ) {
                    this.infoPopup.remove( );
                }
            },
            async addRoute( ) {
                //get direction
                const { to, from } = this;
                const { success, data } = await MapDirectionService.getDirection( this.travelMode, [ [ from.lng, from.lat ], [ to.lng, to.lat ] ] );
                if ( !success ) {
                    return;
                }
                const route = data.routes[ 0 ];
                this.estimatedTimeInSeconds = route.duration;
                const coordinates = route.geometry.coordinates;
                
                //add route on map
                this.map.addLayer( {
                    id: 'route',
                    type: 'line',
                    source: {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates
                            }
                        }
                    },
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': '#3887be',
                        'line-width': 5,
                        'line-opacity': 0.75
                    },
                } );
                //add popup with info direction
                const middleCoordinate = coordinates[ Math.floor( ( coordinates.length - 1 ) / 2 ) ];
                this.infoPopup = new this.mapsApi.Popup( 
                    { 
                        closeOnClick: false,
                        closeButton: false,
                        anchor: 'top'
                    } )
                    .setLngLat( middleCoordinate )
                    .setOffset( [ 0, 50 ] )
                    .addClassName( 'gst-map-directions__estimated-time' )
                    .setDOMContent( this.$refs.estimation )
                    .addTo( this.map );
                //fit all coordinates of the route
                const bounds = new this.mapsApi.LngLatBounds( to, from );
                coordinates.forEach( item => bounds.extend( item ) );
                this.map.fitBounds( bounds, { padding: this.fitBoundsPadding } );
            }
        },
        mounted() {
            this.init();
        },
        beforeDestroy() {
            if ( this.map ) {
                this.map.off( 'idle', this.addRoute );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-map-directions__estimated-time {
        .mapboxgl-popup-tip {
            display: none;
        }

        .mapboxgl-popup-content {
            padding: theme-spacing( 1, 2 );
            background-color: theme-color( 'primary' );
            color: theme-color( 'white' );
            font-family: $font-family;

            .walking-time-icon {
                margin-right: theme-spacing( 1 );
            }
        }
    }
</style>
