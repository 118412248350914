<template>
    <v-flex class="d-flex flex-column align-center justify-center u-height-100">
        <div>
            <BaseImage
                ref="image"
                :src="imageUrl"
                height="auto"
                :width="$vuetify.breakpoint.mdAndUp ? 400 : 222">
                <template v-slot:fallback>
                    <v-flex class="gst-ticket-no-seats-available d-flex flex-column align-center justify-center u-height-100">
                        <BaseIcon class="gst-ticket-no-seats-available__image mb-2" :width="width" :height="height" symbol-id="icons--no_seats_available" />
                        <template v-if="$slots.default">
                            <slot></slot>
                        </template>
                        <div v-else class="gst-ticket-no-seats-available__content text-center">
                            <h5 class="font-weight-medium mb-2">
                                {{ $t('_common:messages.noSeats.title') }}
                            </h5>
                            <p class="mb-0 tertiary--text">
                                {{ $t('_common:messages.noSeats.text') }}
                            </p>
                        </div>
                    </v-flex>
                </template>
            </BaseImage>
        </div>
    </v-flex>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';

    export default {
        name: 'TicketNoSeatsAvailable',
        components: {
            BaseIcon,
            BaseImage
        },
        props: {
            width: {
                type: [ String, Number ],
                default: null
            },
            height: {
                type: [ String, Number ],
                default: null
            },
            imageUrl: {
                type: String,
                default: null
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-ticket-no-seats-available {
    .gst-ticket-no-seats-available__image {
        ::v-deep .gst-svg-icon {
            fill: theme-color( 'primary' );
        }
    }

    .gst-ticket-no-seats-available__content {
        h5,
        p {
            line-height: line-height( 'l' );
            letter-spacing: -0.41px;
        }

        h5 {
            font-size: font-size( 's' );
        }

        p {
            font-size: font-size( 'xxs' );
        }
    }
}
</style>