const cookiesButton = document.querySelector( '.gst-floating-manage-cookies-button' );
const gap = 8;
const bottomPositionMobile = '8px';
const bottomPositionDesktop = '16px';

function handleInsertedAndUpdate( el, binding ) {
    const elementPosition = el.getBoundingClientRect( );
    const viewPortHeight = window.innerHeight;
    if ( el && binding.value ) cookiesButton.style.bottom = `${viewPortHeight - elementPosition.top + gap}px`;
}
// binding object represents the Totals Container element where the price for hotels or tickets is displayed
export default {
    inserted: function ( el, binding ) {
        handleInsertedAndUpdate( el, binding );
    },
    update: function ( el, binding ) {
        handleInsertedAndUpdate( el, binding );
        if ( el && !binding.value ) cookiesButton.style.bottom = bottomPositionDesktop;
    },
    unbind: function ( el, binding ) {
        if ( !el.hidden && binding.value ) cookiesButton.style.bottom = bottomPositionMobile;
        if ( !el.hidden && !binding.value ) cookiesButton.style.bottom = bottomPositionDesktop;    
    }
};