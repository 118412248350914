<template>
    <BaseImage
        ref="markerImage"
        class="gst-marker-image"
        :src="imageSrc"
        :height="height"
        :width="width"
        contain />
</template>
<script>
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';

    export default {
        name: 'MapMarkerImage',
        components: {
            BaseImage
        },
        props: {
            mapsApi: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            // [ longitude, latitude ]
            coordinates: {
                type: Array,
                required: true
            },
            imageSrc: {
                type: String,
                default: null
            },
            width: {
                type: Number,
                default: null
            },
            height: {
                type: Number,
                default: null
            }
        },
        data( ) {
            return {
                markerInstance: null
            };
        },
        watch: {
            'coordinates': {
                handler: function ( newCoordinates ) {
                    if ( this.markerInstance ) {
                        this.markerInstance.setLngLat( newCoordinates );
                    }
                },
                deep: true
            },

        },
        mounted() {
            this.markerInstance = new this.mapsApi.Marker(
                {
                    element: this.$refs.markerImage.$el,
                    anchor: 'bottom'
                } )
                .setLngLat( this.coordinates )
                .addTo( this.map );
        },
        beforeDestroy( ) {
            if ( this.markerInstance ) {
                this.markerInstance.remove( );
            }
        }
    };
</script>