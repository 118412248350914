<template>
    <div class="gst-map-between-points u-height-100">
        <MapLoader
            v-if="isMounted"
            :map-config="mapConfig">
            <template slot-scope="{ mapsApi, map }">
                <MapMarkerLocation
                    v-if="!isCityCentric"
                    :maps-api="mapsApi"
                    :map="map"
                    :location="markerLocationTo" />
                <MapMarkerLocation
                    :maps-api="mapsApi"
                    :map="map"
                    :location="markerLocationFrom" />
                <MapDirections
                    v-if="!isCityCentric"
                    :maps-api="mapsApi"
                    :map="map"
                    :from="from"
                    :to="to" />
            </template>
        </MapLoader>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import MapLoader from '@tenants/ticketmaster/app/components/map/MapLoader.vue';
    import MapMarkerLocation from '@tenants/ticketmaster/app/components/map/MapMarkerLocation.vue';
    import MapDirections from '@tenants/ticketmaster/app/components/map/MapDirections.vue';
    import locationsConstants from '@tenant/app/utils/constants/locations';
    import mapConstants from '@tenant/app/utils/constants/map';
    
    /**
     * @typedef {Object} Location
     * @property {Number} lng
     * @property {Number} lat
     * @property {String} type
     * @property {String} name
     */

    export default {
        name: 'MapBetweenPoints',
        components: {
            MapLoader,
            MapMarkerLocation,
            MapDirections
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.containers.common.MapBetweenPoints'
        },
        props: {
            /** @type {Location} */
            from: {
                type: Object,
                required: true,
            },
            /** @type {Location} */
            to: {
                type: Object,
                required: true,
            },
        },
        data( ) {
            return {
                isMounted: false,
            };
        },
        computed: {
            ...mapState( {
                location: state => state.addHotelReservationState.hotels.filters.location
            } ),
            isCityCentric() {
                return this.location.type === locationsConstants.TYPES.CITY;
            },
            mapBounds( ) {
                const { from, to } = this;

                return {
                    minLat: Math.min( from.lat, to.lat ),
                    maxLat: Math.max( from.lat, to.lat ),
                    minLng: Math.min( from.lng, to.lng ),
                    maxLng: Math.max( from.lng, to.lng ),
                };
            },
            fitBoundsPadding( ) {
                return this.$vuetify.breakpoint.mdAndUp ? 100 : 50;
            },
            mapConfig( ) {
                if ( this.isCityCentric ) {
                    return {
                        center: [ this.from.lng, this.from.lat ],
                        zoom: mapConstants.DEFAULT_CONFIG.ZOOM_HOTEL_DETAILS
                    };
                }

                return {
                    bounds: [ 
                        [ this.mapBounds.minLng, this.mapBounds.minLat ],
                        [ this.mapBounds.maxLng, this.mapBounds.maxLat ],
                    ],
                    fitBoundsOptions: {
                        padding: this.fitBoundsPadding
                    },
                    interactive: false,
                };
            },
            markerLocationTo( ) {
                const { to } = this;

                return {
                    latitude: to.lat,
                    longitude: to.lng,
                    ...to
                };
            },
            markerLocationFrom( ) {
                const { from } = this;

                return {
                    latitude: from.lat,
                    longitude: from.lng,
                    ...from
                };
            }
        },
        mounted( ) {
            this.isMounted = true;
        }
    };
</script>
