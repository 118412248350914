<template>
    <div class="hotel-details-overview d-flex flex-column"
        :class="{
            'hotel-details-overview--padding-bottom': isPackageFlow
        }">
        <div class="hotel-details-overview__description">
            <p class="pb-0 mb-0 pb-md-1 mb-md-4">
                {{ item.description }}
            </p>
        </div>
        <div v-if="hasServices" class="hotel-details-overview__amenities d-flex flex-wrap flex-md-column flex-md-nowrap ">
            <span class="hotel-details-overview__amenities__title u-width-100 mb-3">
                {{ $t('amenities') }}
            </span>
            <div class="amenities-container d-flex flex-wrap">
                <div v-for="(elem, index) in services" :key="index" class="hotel-details-overview__amenities__elements d-flex flex-row align-center">
                    <IconRoomAmenity :id="elem.id" class="amenity-icon" />
                    <span>
                        {{ elem.name }}
                    </span>
                    <BaseTooltip
                        v-if="elem.tooltip"
                        bottom
                        :text="elem.tooltip"
                        class-content="hotel-details-overview__amenities__tooltip-content"
                        class-activator="hotel-details-overview__amenities__tooltip-activator d-flex"
                        class="hotel-details-overview__amenities__tooltip">
                        <HelpIcon />
                    </BaseTooltip>
                </div>
            </div>
        </div>
        <div ref="map" class="hotel-details-overview_map d-flex flex-column d-md-block">
            <span class="hotel-details-overview_map__title u-width-100 mb-3">
                {{ mapTitle }}
            </span>
            <MapBetweenPoints v-if="displayMap" :from="mapFrom" :to="mapTo" />
        </div>
        <BaseButton v-if="$vuetify.breakpoint.smAndDown && isPackageFlow && !isSelected"
            :placeholder="$t('_common:buttons.selectPackage')"
            :disabled="isSelected"
            color="primary"
            class="hotel-details-overview__call-to-action"
            @click="$emit('select')" />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import HelpIcon from '@tenant/app/assets/icons/help.svg';
    import MapBetweenPoints from '@tenants/ticketmaster/app/components/map/MapBetweenPoints.vue';  
    import { getTranslateKeyForType } from '@tenants/ticketmaster/app/utils/locations';
    import locationsConstants from '@tenant/app/utils/constants/locations';
    import IconRoomAmenity from '../../../icons/IconRoomAmenity';

    export default {
        name: 'HotelDetailsOverviewTab',
        components: {
            IconRoomAmenity,
            HelpIcon,
            BaseTooltip,
            MapBetweenPoints,
            BaseButton
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.modal._components._components.hotelDetailsModalBody._components.hotelDetailsModalOverviewTab'
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            isSelected: {
                type: Boolean,
                default: false
            },
            isPackageFlow: {
                type: Boolean,
                default: false
            },
            inLocation: {
                type: Object,
                default: () => ( {} )
            },
        },
        data( ) {
            return {
                observer: null,
                displayMap: false,
            };
        },
        computed: {
            ...mapState( {
                location: state => state.addHotelReservationState.hotels.filters.location
            } ),
            isCityCentric() {
                return this.location.type === locationsConstants.TYPES.CITY;
            },
            mapTitle() {
                return !this.isCityCentric ? this.$t( 'gettingToEvent' ) : this.$t( 'hotelLocation' );
            },
            services( ) {
                const { item, hasServices } = this;

                return hasServices ? item.services.map( item => {
                    if ( !item.included && item.price ) {
                        const priceFormatted = this.$options.filters.currencyFilter( item.price, item.currency );
                        const tooltip = `${item.name} ${priceFormatted}`;
                        const name = this.$options.filters.appendCurrencySymbol( item.name, item.currency );

                        return {
                            ...item,
                            name: name,
                            tooltip: tooltip
                        };
                    }

                    return item;
                } ) : [];
            },
            hasServices( ) {
                const { item } = this;
                return !!item.services.length;
            },
            mapFrom ( ) {
                const { latitude, longitude } = this.item;
                return {
                    lat: Number.parseFloat( latitude ),
                    lng: Number.parseFloat( longitude ),
                    type: locationsConstants.TYPES.HOTEL
                };
            },
            mapTo ( ) {
                const { venueLongitude, venueLatitude } = this.event;
                const { longitude, latitude, type } = this.inLocation;

                return {
                    lat: Number.parseFloat( latitude || venueLatitude ),
                    lng: Number.parseFloat( longitude || venueLongitude ),
                    type: type || locationsConstants.TYPES.VENUE,
                    name: this.$t( getTranslateKeyForType( this.inLocation ) ),
                };
            },
        },
        methods: {
            onElementObserved( intersection ) {
                if( !intersection[0].isIntersecting ) {
                    return;
                }
                this.displayMap = true;
                this.observer.unobserve( intersection[0].target );
            },
            onCallToActionClickDo() {
                this.busEventParent.$emit( 'select', this.item );
            }
        },
        mounted() {
            this.observer = new IntersectionObserver(
                this.onElementObserved,
                {
                    root: null,
                    threshold: 0.01
                }
            );

            this.observer.observe( this.$refs['map'] );
        },
        beforeDestroy() {
            this.observer.disconnect();
        },
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.hotel-details-overview {
    padding: theme-spacing( 4, 4, 0, 4 );
    margin-bottom: 164px;

    .hotel-details-overview__description {
        width: 100%;
        padding-left: theme-spacing( 0 );
        flex: 1;

        p {
            color: theme-color( 'black' );
        }
    }

    .hotel-details-overview__amenities__title,
    .hotel-details-overview_map__title {
        display: block;
        line-height: line-height( 'xxl' );
        color: theme-color( 'black' );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'bold' );
    }

    .hotel-details-overview__amenities {
        .amenities-container {
            .hotel-details-overview__amenities__elements {
                width: 33%;
                padding: theme-spacing( 1, 8, 1, 0 );
                background-color: theme-color( 'white' );
                color: theme-color( 'success' );

                span {
                    padding-left: theme-spacing( 4 );

                    &:only-child {
                        padding-left: theme-spacing( 8 );
                    }
                }

                svg {
                    height: 18px;
                    width: 18px;
                    min-width: 18px;

                    .gst-svg-icon {
                        stroke: theme-color( 'tertiary' ) !important;
                        transition: all 0.2s ease-in;
                    }

                    .gst-svg-icon:nth-child(2) {
                        fill: theme-color( 'transparent' ) !important;
                    }
                }
            }
        }
    }

    .hotel-details-overview_map {
        height: 25vw;
        width: 100%;

        .hotel-details-overview_map__title {
            padding: theme-spacing( 4 ) theme-spacing( 0 );
        }
    }
}

@include mobile-only {
    .hotel-details-overview {
        padding: theme-spacing( 4 ) theme-spacing( 0 );
        margin-bottom: 0;

        .hotel-details-overview__description {
            padding: theme-spacing( 4, 4 );
            order: 2;

            p {
                line-height: 22px;
                font-size: font-size( 'l' );
            }
        }

        .hotel-details-overview__amenities {
            padding: theme-spacing( 0, 4, 0, 4 );
            order: 1;

            .amenities-container {
                .hotel-details-overview__amenities__elements {
                    width: 50%;
                    padding-right: theme-spacing( 4 );
                    flex: 50%;
                }
            }
        }

        .hotel-details-overview_map {
            height: 50vh;
            width: 100%;
            order: 3;

            .hotel-details-overview_map__title {
                padding-left: theme-spacing( 4 );
            }
        }

        .hotel-details-overview__call-to-action {
            position: fixed;
            bottom: 0;
            height: 40px;
            width: 100%;
            font-weight: font-weight( 'large' );
            order: 4;
            border-radius: border-radius( 'none' ) !important;
            text-transform: none;
        }
    }

    .hotel-details-overview--padding-bottom {
        padding-bottom: 40px;
    }
}
</style>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .hotel-details-overview__amenities__tooltip-content.v-tooltip__content {
        width: auto !important;
        padding: theme-spacing( 4 ) !important;
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' ) !important;
        border-radius: border-radius( 'xs' );
        box-shadow: 0 2px 6px rgba( 0, 0, 0, 0.25 ) !important;
        opacity: 1 !important;
    }

    .hotel-details-overview__amenities__tooltip-activator {
        margin-left: auto;
    }
</style>
