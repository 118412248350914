<template>
    <MapMarkerImage
        :maps-api="mapsApi"
        :map="map"
        :coordinates="coordinates"
        :image-src="imageSrc"
        :height="62"
        :width="46" />
</template>

<script>
    import locationsConstants from '@tenant/app/utils/constants/locations';
    import MapMarkerImage from './MapMarkerImage.vue';

    export default {
        name: 'MapMarkerLocation',
        components: { MapMarkerImage },
        props: {
            mapsApi: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            location: {
                type: Object,
                required: true
            }
        },
        computed: {
            coordinates() {
                return [ this.location.longitude, this.location.latitude ];
            },
            imageSrc( ) {
                switch( this.location.type ) {
                    case locationsConstants.TYPES.CITY:
                        return require( '@tenant/app/assets/marker_city.png' );
                    case locationsConstants.TYPES.HOTEL:
                        return require( '@tenant/app/assets/marker_hotel.png' );
                    default:
                        return require( '@tenant/app/assets/marker_venue.png' );
                }
            }
        }
    };
</script>