import { render, staticRenderFns } from "./TicketNoSeatsAvailable.vue?vue&type=template&id=c78eb5ba&scoped=true&"
import script from "./TicketNoSeatsAvailable.vue?vue&type=script&lang=js&"
export * from "./TicketNoSeatsAvailable.vue?vue&type=script&lang=js&"
import style0 from "./TicketNoSeatsAvailable.vue?vue&type=style&index=0&id=c78eb5ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c78eb5ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex})
