<template>
    <v-carousel v-model="activeSlide"
        hide-delimiters
        class="gst-hotel-rooms-carousel"
        height="360"
        v-bind="$attrs">
        <v-carousel-item v-for="(image, index) in imagesToDisplay"
            :key="index">
            <BaseImage :src="image.src" :fallback-src="image.fallback" @error-load="onImageErrorLoadDo( image.src )" />
        </v-carousel-item>
        <v-carousel-item v-if="!imagesToDisplay.length">
            <BaseImage class="gst-room-card-variant-3__gallery__fallback" />
        </v-carousel-item>
        <p class="gst-hotel-rooms-carousel__active-slide">
            {{ activeSlideNumber }}
        </p>
    </v-carousel>
</template>

<script>
    import { getUnique as getUniqueArray } from '@core/utils/arrayUtils';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';

    export default {
        name: 'HotelRoomsCarousel',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.eventPackageOffer.hotelRoomsCarousel'
        },
        components: {
            BaseImage
        },
        props: {
            images: {
                type: Array,
                default: new Array()
            }
        },
        data() {
            return {
                activeSlide: 0,
                displayNavigation: false,
                errorLoadImages: []
            };
        },
        computed: {
            imagesToDisplay ( ) {
                return this.images
                    .map( ( image ) => {
                        return {
                            src: getImageByWidth( IMAGE_SIZE.LARGE, image ),
                            fallBack: getImageByWidth( IMAGE_SIZE.ORIGINAL, image )
                        };
                    } )
                    .filter( item => this.errorLoadImages.indexOf( item.src ) === -1 );
            },
            activeSlideNumber( ) {
                return this.$t( 'activeSlideNumber', { current: this.activeSlide + 1, total: this.imagesToDisplay.length } );
            }
        },
        methods: {
            onImageErrorLoadDo( item ) {
                this.errorLoadImages = getUniqueArray( [
                    ...this.errorLoadImages,
                    item
                ] );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-hotel-rooms-carousel {
    position: relative;
    width: 260px;
    padding: 3px;
    margin-bottom: 19px;
    max-height: 360px;

    .v-image {
        height: 100% !important;
    }

    .v-window__next,
    .v-window__prev {
        background-color: theme-color( 'white' ) !important;

        .gst-svg-icon {
            fill: theme-color( 'primary' );
        }
    }

    .gst-hotel-rooms-carousel__active-slide {
        position: absolute;
        right: 12px;
        bottom: 8px;
        padding: 8px;
        background-color: rgba( 0, 0, 0, 0.65 );
        color: white;
        font-size: 12px;
        border-radius: 2px;
    }

    @include mobile-only {
        width: 100%;
        margin-bottom: theme-spacing( 4 );
    }
}
</style>
