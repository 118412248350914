<template>
    <div class="d-flex align-center gst-hotel-details-header__location d-flex">
        <IconLocation />
        <div class="pl-2 d-flex align-center mb-md-0">
            <div class="gst-hotel-details-header__location__title">
                {{ item.address | fullLocation }} - {{ !isCityCentric ? `${distance} -` : '' }}
                <BaseButton class="gst-btn--link text-lowercase" @click="$emit( 'show-map' )">
                    {{ highlightText && `${highlightText} -` }} {{ $t('showOnMap') }}
                </BaseButton>
            </div>
            <span v-if="accessibilityText">
                {{ `- ${accessibilityText}` }}
            </span>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import locationsConstants from '@tenants/ticketmaster/app/utils/constants/locations';
    import IconLocation from '../../../assets/svg/location.svg';

    export default {
        name: 'HotelDetailsAddressBar',
        components: {
            IconLocation,
            BaseButton
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.modal._components._components.hotelDetailsAddressBar'
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            inLocation: {
                type: Object,
                default: () => {},
            },
            distanceFrom: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            ...mapState( {
                location: state => state.addHotelReservationState.hotels.filters.location
            } ),
            isCityCentric() {
                return this.location.type === locationsConstants.TYPES.CITY;
            },
            accessibilityText() {
                return this.item.accessibility ? this.item.accessibility.map( key => this.$t( `accessibility.${key}` ) ).join( ', ' ) : '';
            },
            highlightText() {
                return this.item.highlights ? this.item.highlights.map( key => this.$t( `highlights.${key}` ) ).join( ', ' ) : '';
            },
            /**
             * inLocation was added for Open inventory and contains the last searched location.
             * Otherwise the event.venueCountry will be the base for distance calculation
             */
            distanceUnitOfMeasure( ) {
                if ( this.inLocation?.countryCode ) {
                    return getDistanceUnitOfMeasureForCountry ( this.inLocation.countryCode );
                } else {
                    return getDistanceUnitOfMeasureForCountry ( this.event.venueCountry );
                }
            },
            distance( ) {
                if ( this.distanceFrom ) {
                    return this.$options.filters.distanceFrom(
                        this.item.distance,
                        this.distanceUnitOfMeasure,
                        this.$t( this.inLocation.type === locationsConstants.TYPES.CITY ? '_common:terms.cityCenter' : '_common:terms.venue' )
                    );
                }
                return this.$options.filters.distanceToVenue( this.item.distance, this.distanceUnitOfMeasure );
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-hotel-details-header__location {
    width: 100%;
    color: theme-color( 'tertiary' );
    font-size: font-size( 's' );

    .gst-hotel-details-header__location__title {
        position: relative;
        bottom: theme-spacing( 1 );
    }

    svg {
        position: relative;
        left: theme-spacing( 1 );
        bottom: 2px;
        height: 24px;
        width: 24px;
    }
}

@include mobile-only {
    .gst-hotel-details-header__location {
        padding: theme-spacing( 0, 4 );

        svg {
            bottom: auto;
        }

        div {
            width: calc( 100% - 20px );
            margin-bottom: 0;
            font-size: font-size( 's' );

            span {
                display: inline-block;
                width: 100%;
            }
        }
    }
}
</style>
