export default {
    /**
     * MapBox profiles
     * 
     * https://docs.mapbox.com/help/glossary/routing-profile/
     */
    TRAVEL_MODE: {
        DRIVING: 'driving',
        WALKING: 'walking'
    }
};
